import React from "react";
import { graphql, Link } from "gatsby";
import Card from "react-bootstrap/Card";

export default function InnovationsPage({ data }) {
  return (
    <>
      <h1>Innovations</h1>
      {data.allMarkdownRemark.nodes.map(node => (
        <Card className="blog-preview-card" key={node.frontmatter.slug}>
          <Link to={node.frontmatter.slug}>
            <Card.Header>{node.frontmatter.title}</Card.Header>
            <Card.Body>
              <Card.Title>{node.frontmatter.subtitle}</Card.Title>
              <Card.Text>{node.frontmatter.shortdesc}</Card.Text>
              <span style={{ textDecoration: "underline", color: "blue" }}>
                Read More...
              </span>
            </Card.Body>
          </Link>
        </Card>
      ))}
    </>
  );
}

export const query = graphql`
  {
    allMarkdownRemark {
      nodes {
        excerpt
        frontmatter {
          slug
          title
          subtitle
          shortdesc
        }
      }
    }
  }
`;
